/* Base styling for the containers */
.sign-in-page {
    display: flex;
    height: 100vh;
    background: linear-gradient(to bottom, #0F123B, #000);
    color: #fff;
    font-family: 'Plus Jakarta Sans', sans-serif;
}

/* Left container styling */
.sign-in-page .left-container {
    flex: 1;
    background-image: url("../../../assets/images/dhwani-login.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

/* Right container styling */
.sign-in-page .right-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 15px;
    padding: 20px;
    overflow: auto;
}

/* Text and input styling */
.sign-in-page .right-container__h2 {
    font-size: 30px;
    margin-bottom: 10px;
}

.sign-in-page .right-container__p {
    opacity: .5;
    margin: 5px 0 20px;
}

.sign-in-page .right-container__input {
    width: 100%;
    max-width: 350px;
    padding: 15px 20px;
    border-radius: 100px;
    border: 1px solid rgba(255, 255, 255, .5);
    background: transparent;
    color: #fff;
}

.sign-in-page .right-container__input:focus {
    outline: none;
}

.sign-in-page .right-container__label {
    margin-top: 20px;
    font-size: 14px;
}

/* Bottom text styling */
.sign-in-page .right-container__bottom-text {
    text-align: center;
    margin-top: 22px;
    font-size: 14px;
    color: rgba(255, 255, 255, .5);
}

.sign-in-page .right-container__bottom-text > strong {
    color: #fff;
}

/* Input container */
.sign-in-page .input-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
    max-width: 350px;
}

/* Toggle container and checkbox styling */
.sign-in-page .toggle-container {
    margin: 24px 0;
    font-size: 12px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.sign-in-page .toggle-box {
    appearance: none;
    width: 32px;
    height: 16px;
    border-radius: 20px;
    background-color: rgba(255, 255, 255, .5);
    cursor: pointer;
    position: relative;
}

.sign-in-page .toggle-box:checked {
    background-color: #30A2FF;
}

.sign-in-page .toggle-box:checked::after {
    transform: translateX(16px);
    transition: .4s ease-in-out;
}

.sign-in-page .toggle-box::after {
    position: absolute;
    content: "";
    top: 3px;
    left: 3px;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: #fff;
}

/* Button styling */
.sign-in-page .btn {
    background-color: #30A2FF;
    border: 1px solid #30A2FF;
    width: 100%;
    max-width: 350px;
    border-radius: 20px;
    padding: 10px 0;
    color: #fff;
}

/* Media query to hide the image on small screens */
@media (max-width: 768px) {
    .sign-in-page .left-container {
        display: none;
    }
    
    .sign-in-page .right-container {
        flex: 1;
        justify-content: center;
        width: 100%; /* Ensure the right container takes up the full width */
        padding: 20px; /* Add padding to ensure proper spacing */
    }
}
